import { combineReducers } from "redux";
import selectedPersonData from "./reducers";
import profileData from "./linkedinpagereducer";
import emailReducer from "./emailreducer";
import headerReducer from "./headerReducer";
import loaderReducer from "./loaderReducer";
import radioButtonValueReducer from "./radioValue";
import updateSalesForceValue from "./salesforceDataReducer";
import updateGetProfileData from "./getProfileSfReducer";
import nameValueReducer from "./nameValueReducer";
import selctedValueReducer from "./selctedValueReducer";
import { selectedStateReducer } from "./selectedStateReducer";


const rootReducer: any = combineReducers({
  selectedPersonData,
  profileData,
  emailReducer,
  headerReducer,
  loaderReducer,
  radioButtonValueReducer,
  updateSalesForceValue,
  updateGetProfileData,
  nameValueReducer,
  selctedValueReducer,
  selectedState: selectedStateReducer

});

export default rootReducer;
