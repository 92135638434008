/*istanbul ignore file*/
import { useState, useEffect } from "react";
import Slider, { SliderValueLabelProps } from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import "./generateMail.css";
import Button from "@mui/material/Button";
import { FormControl, MenuItem, Select, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailDetailFailure,
  getEmailDetailStart,
  getEmailDetailSuccess,
  setHeaderStepThreeCompleted,
  setLoader,
} from "../Services/Actions/actionCreators";
import Emailpage from "./Email/Emailpage";
import Loader from "../Common/Loader/Loader";
import { contactDetails, generateMail } from "../Services/Actions/actions";
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledButton = styled(Button)({
  textTransform: "none", 
});

const marks = [
  {
    value: 100,
    label: "100",
  },
  {
    value: 500,
    label: "500",
  },
];

function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

export default function GenerateMail() {
  const dispatch = useDispatch();
  const [wordLimit, setWordLimit] = useState<any>("280");
  const [errors, setErrors] = useState<any>({});
  const [domainType, setDomainType] = useState<any>("");
  const [fotmatType, setFormatType] = useState<any>("");
  const [companyType, setCompanyType] = useState<any>("");
  const [manualAddition, setManualAddition] = useState<any>("");
  const [mailView, setMailView] = useState(false);
  const [contacts, setContacts] = useState<any>();
  const [emailApiResponce, setEmailApiResponce] = useState([] as any[]);

  const mailDetail = useSelector((state: any) => state.emailReducer.mailDetail);
  const loaderStore = useSelector((state: any) => state.loaderReducer?.loader);
  const selectedRadioValue = useSelector(
    (state: any) => state.radioButtonValueReducer.selectedRadioValue
  );

  const selectedPersonVid = useSelector(
    (state: any) => state?.selectedPersonData?.selectedVid
  );
  const salesForceList = useSelector(
    (state: any) => state?.profileData?.salesForceList
  );

  const handleChange = (event: any, newValue: any) => {
    setWordLimit(newValue);
  };

  /* istanbul ignore next */
  const validateForm = () => {
    if (companyType === "" || domainType === "" || fotmatType === "") {
      setErrors({
        companyType: companyType === "" ? "error" : "",
        domainType: domainType === "" ? "error" : "",
        fotmatType: fotmatType === "" ? "error" : "",
      });
      return false;
    }
    return true;
  };

  /* istanbul ignore next */
  const handleSubmit = async () => {
    if (validateForm()) {
      dispatch(setLoader(true));
      dispatch(getEmailDetailStart());
      
      try {
        let payload = {
          data: {
            properties:
              selectedRadioValue === "hubspot"
                ? {
                    linkedin_contact_profile_url: contacts.map((url: any) => ({
                      url: url?.data?.properties.linkedin_contact_profile_url,
                      owner_name: url?.owner_name,
                      owner_email: url?.owner_email,
                      email: url?.data?.properties?.email,
                    })),
                  }
                : {
                    linkedin_contact_profile_url: contacts.map((data: any) => ({
                      url:
                        data?.data?.properties?.linkedin_contact_profile_url ||
                        "",
                      owner_name: data?.owner_name,
                      owner_email: data?.owner_email,
                      email: data?.data?.properties?.email,
                    })),
                  },
          },
          word_limit: wordLimit,
          company_type: companyType,
          domain_type: domainType,
          format_type: fotmatType,
          manual_addition: manualAddition,
        };

        await generateMail(payload).then((response) => {
          setEmailApiResponce(response);
          if (response.status === 200 && response.data.length > 0) {
            dispatch(setLoader(true));
            dispatch(getEmailDetailSuccess(response.data[0]));
            dispatch(setLoader(false));
          }
        });
      } catch (error) {
        dispatch(getEmailDetailFailure(error));
        dispatch(setLoader(false));
        dispatch(setLoader(false));
      }
      dispatch(setHeaderStepThreeCompleted());
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (selectedRadioValue === "hubspot") {
      contactDetails(selectedPersonVid, dispatch).then((res) => {
        setContacts(res?.data);
      });
    } else {
      
      if (salesForceList && selectedPersonVid) {
        const findData = salesForceList.filter((item: any) =>
          selectedPersonVid.includes(item.data.id)
        );
        setContacts(findData);
      } else {
        setContacts([]); 
      }
    }
  }, [salesForceList, selectedPersonVid, selectedRadioValue]);
  

  useEffect(() => {
    if (mailDetail) {
      setMailView(true);
    }
  }, [mailDetail]);

  useEffect(() => {
    if (mailDetail) {
      setMailView(true);
    }
  }, [mailDetail]);

  /* istanbul ignore next */
  return (
    <Box sx={{ width: "100%" }}>
      {mailView ? (
        <Emailpage
          emailApiResponce={emailApiResponce}
          setMailView={setMailView}
          domainType={domainType}
        />
      ) : (
        <Box className="comp-box-header">
          <Grid container={true} spacing={2} columns={12} className="table-box">
            <Grid item={true} xs={8} className="custom-grid-box1">
              <Box className="heading-custom">
                <Typography fontSize={"24px"} fontWeight={700}>
                  Generate Email
                </Typography>
              </Box>
              <Box className="slider-txt-box">
                <Typography className="page-text">
                  Select the word limit
                </Typography>
                <Typography gutterBottom={true}>{wordLimit}</Typography>
              </Box>
              <Box className="custom-main-box">
                <Box>
                  <Slider
                    className="custom-slider-minvalue"
                    valueLabelDisplay="auto"
                    onChange={handleChange}
                    slots={{
                      valueLabel: ValueLabelComponent,
                    }}
                    aria-label="custom thumb label"
                    min={100}
                    max={500}
                    defaultValue={280}
                    marks={marks}
                  />
                </Box>

                <Box className="custom-dropdown-box">
                  <Typography className="custom-slect-text">
                    Select the company type/business unit{" "}
                    <span className="mandatory">*</span>
                  </Typography>

                  <FormControl sx={{ m: 1, minWidth: 520 }}>
                    <Select
                      className="custom-select-drpdn"
                      value={companyType}
                      onChange={(e) => {
                        setErrors({ ...errors, companyType: "" });
                        setCompanyType(e.target.value);
                      }}
                      displayEmpty={true}
                      inputProps={{ "aria-label": "Without label" }}
                      error={errors.companyType}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem className="custom-menu-item" value="">
                        <Typography>
                          Select the company type/business unit{" "}
                        </Typography>
                      </MenuItem>
                      <MenuItem value="Compunnel Inc.">Compunnel Inc.</MenuItem>
                      <MenuItem value={"Compunnel Digital"}>
                        Compunnel Digital
                      </MenuItem>
                      <MenuItem value={"Compunnel Healthcare"}>
                        Compunnel Healthcare
                      </MenuItem>
                      <MenuItem value={"Compunnel Staffing"}>
                        Compunnel Staffing
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className="custom-dropdown-box">
                  <Typography className="custom-slect-text">
                    Select the domain type <span className="mandatory">*</span>
                  </Typography>

                  <FormControl sx={{ m: 1, minWidth: 520 }}>
                    <Select
                      className="custom-select-drpdn"
                      value={domainType}
                      onChange={(e) => {
                        setErrors({ ...errors, domainType: "" });
                        setDomainType(e.target.value);
                      }}
                      displayEmpty={true}
                      inputProps={{ "aria-label": "Without label" }}
                      error={errors.domainType}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem className="custom-menu-item" value="">
                        <Typography>Select the domain type</Typography>
                      </MenuItem>
                      <MenuItem value="Data Solutions">Data Solutions</MenuItem>
                      <MenuItem value={"AI Solutions"}>AI Solutions</MenuItem>
                      <MenuItem value={"Healthcare Staffing"}>
                        Healthcare Staffing
                      </MenuItem>
                      <MenuItem value={"IT & Non-IT Staffing"}>
                        IT & Non-IT Staffing
                      </MenuItem>
                      <MenuItem value={"Microsoft Services & Solutions"}>
                        Microsoft Services & Solutions
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box className="custom-dropdown-box">
                  <Typography className="custom-slect-text">
                    Select the format type <span className="mandatory">*</span>
                  </Typography>

                  <FormControl sx={{ m: 1, minWidth: 520 }}>
                    <Select
                      className="custom-select-drpdn"
                      value={fotmatType}
                      onChange={(e) => {
                        setErrors({ ...errors, fotmatType: "" });
                        setFormatType(e.target.value);
                      }}
                      displayEmpty={true}
                      inputProps={{ "aria-label": "Without label" }}
                      error={errors.fotmatType}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem className="custom-menu-item" value="">
                        <Typography>Select the format type</Typography>
                      </MenuItem>
                      <MenuItem value="Formal">Formal</MenuItem>
                      <MenuItem value={"Semi-Formal"}>Semi-Formal</MenuItem>
                      <MenuItem value={"Casual"}>Casual</MenuItem>
                      <MenuItem value={"Follow-Up"}>Follow-Up</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem={true}
              className="custom-virt-divider"
            />
            <Grid item={true} xs={4} className="custom-grid-box2">
              <Box>
                <Typography fontSize={"18px"} fontWeight={"600"}>
                  Manual Addition to Prompt
                </Typography>
                <Box>
                  <TextField
                    className="custom-input"
                    id="filled-basic"
                    label=""
                    placeholder="Please add mannual prompt"
                    variant="filled"
                    multiline={true}
                    fullWidth={true}
                    onChange={(e) => setManualAddition(e.target.value)}
                  />
                </Box>
              </Box>
              <Box className="custom-btn">
                {loaderStore ? (
                  <StyledButton
                    variant="contained"
                    className="generate-mail-btn btn-gld"
                    data-testid="generate-mail-button"
                    onClick={handleSubmit}
                    disabled={loaderStore}
                  >
                    Generate Email
                  </StyledButton>
                ) : (
                  <StyledButton
                    variant="contained"
                    className="generate-mail-btn btn-gld"
                    data-testid="generate-mail-button"
                    onClick={handleSubmit}
                  >
                    Generate Email
                  </StyledButton>
                )}
              </Box>
              {loaderStore ? <Loader /> : ""}
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
