import { FunctionComponent, useEffect, useState, FC } from "react";
import "./mailReview.css";
import { Box, Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { setLoader } from "../../Services/Actions/actionCreators";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import {
  fetchMailOfContact,
  mailReviewContacts,
  searchMailReviewContacts,
} from "../../Services/Actions/actions";
import searchScope from "../../Assets/Icons/searchScope.svg";
import { useDispatch } from "react-redux";
import Loader from "../../Common/Loader/Loader";
import { IconButton, TextField } from "@mui/material";
import Pagination from "../../Common/Pagination/Pagination";
interface MailReviewProps {
  isTesting?: boolean;
}
const MailReview: FC<MailReviewProps> = ({ isTesting }) => {
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(0);
  const [emailView, setEmailView] = useState(false);
  const [mailContacts, setMailContacts] = useState(true);
  const [contacts, setContacts] = useState<any[]>([]);
  const [contactsMailData, setContactsMailData] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<string | false>(`panel0`);
  const [value, setValue] = useState("");
  const [totalContactsNumber, setTotalContactsNumber] = useState<any[]>([]);
  const [page, setPage] = useState(0);

  let pageSize = 5;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const mail_review_contacts = async () => {
    dispatch(setLoader(true));
    setMailContacts(true);
    await mailReviewContacts({ cursor: pageIndex, offset: pageSize }).then(
      (res: any) => {
        setContacts(res?.data?.records);
        setTotalContactsNumber(res?.data?.total_records);
      }
    );
    setMailContacts(false);
    dispatch(setLoader(false));
  };

  const handleSearch = async () => {
    if (value) {
      dispatch(setLoader(true));
      await searchMailReviewContacts(value).then((res: any) => {
        setContacts(res?.data);
        setTotalContactsNumber(res?.data?.length);
      });
      dispatch(setLoader(false));
    } else {
      dispatch(setLoader(true));
      mailReviewContacts({ cursor: pageIndex, offset: pageSize }).then(
        (res: any) => {
          setContacts(res?.data?.records);
          setTotalContactsNumber(res?.data?.total_records);
        }
      );
      dispatch(setLoader(false));
    }
  };

  const handleClick: any = async (item: any) => {
    let payloadEle = {
      Company: item?.Company,
      Job_Title: item?.Job_Title,
      Email: item?.Email,
      Sender_Company: item?.Sender_Company,
      Sender_Domain: item?.Sender_Domain,
      LinkedIn_Username: item?.LinkedIn_Username,
      queue_name: item?.queue_name,
    };

    dispatch(setLoader(true));
    setEmailView(true);
    await fetchMailOfContact(payloadEle).then((res) => {
      if (res?.data?.length > 0) {
        setContactsMailData(res?.data);
      }
    });
    setEmailView(false);
    dispatch(setLoader(false));
  };

  const handleEmailChange = (e: any) => {
    const { name, value } = e.target;
    setContactsMailData({
      ...contactsMailData,
      [name]: value,
    });
  };

  useEffect(() => {
    mail_review_contacts();
  }, [pageIndex]);
  useEffect(() => {
    if (isTesting) {
      mail_review_contacts()
      handleSearch()
    }
    handleClick({}

    )
    getInitials("test")
  }, []);
  const handleNext = (event: any, newPage: number) => {
    setPageIndex(newPage);
    setPage(newPage);
  };

  const getInitials = (name: any) => {
    let initials = "";
    name.split(" ").map((subName: any) => (initials = initials + subName[0]));
    return initials;
  };

  return (
    <Box className="cust-frame-parent-MR">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box className="cust-frame-parent">
            <Box className="mail-review">Email Review</Box>
            <Box className="available-lists-16">Contacts</Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "2%",
                width: "100%",
              }}
            >
              <TextField
                id="outlined-search"
                size="small"
                label="Search contacts"
                type="search"
                onChange={(e) => setValue(e.target.value)}
                className="search-text-field"
              />
              <IconButton
                className="search-icon"
                data-testid="search-btn"
                onClick={handleSearch}
              >
                <img className="custom-img" src={searchScope} />
              </IconButton>
            </Box>
            {!mailContacts ? (
              <Box className="list-div-mailreview">
                <List className="custom-list-mailReview" data-testid="list-btn" disablePadding>
                  {contacts?.map((item: any) => (
                    <ListItem className="cust-sublist"
                      key={item.id}
                      
                      disablePadding
                    >
                      <ListItemButton
                        className="custm-ListItemButton"
                        onClick={() => handleClick(item)}
                        data-testid="list-btn"
                      >
                        <Box className="group-parent">
                          <Box className="contacts-details-container">
                            <Box className="ellipse-parent">
                              <Box className="group-child" />
                              <b className="at">{getInitials(item?.Name)}</b>
                            </Box>
                            <Box className="contacts-detailss">
                              <p className="amanda-thurston">{item?.Name}</p>
                              <p className="amandathurstonhotmailcom">
                                {item.Email}
                              </p>
                              <p className="amandathurstonhotmailcom">
                                {item.Company}
                              </p>
                              <p className="amandathurstonhotmailcom">
                                {item.Job_Title}
                              </p>
                            </Box>
                          </Box>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                <Box className="table-pagination">
                  <Pagination
                    count={totalContactsNumber}
                    pageSize={pageSize}
                    handleNext={handleNext}
                    page={page}
                    data-testid="paginat"
                  />
                </Box>
              </Box>
            ) : (
              <Loader />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={8}>
          {!emailView ? (
            <Box className="frame-parent4">
              {contactsMailData?.map((data: any, index: any) => (
                <Accordion
                  key={data.Subject}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  className="accordion-box-mailReview"
                  data-testid="accordion-list"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                      {data?.Subject} <br />
                      <span className="thu-26102023-1530">{data?.Sent_On}</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="custom-sec-box-mailReview">
                    <Box className="frame-parent5">
                      <Box className="hi-amanda-lorem-container">
                        <TextField
                          id="standard-basic"
                          label=""
                          variant="standard"
                          value={data?.Body}
                          className="Custom-textfield2"
                          multiline={true}
                          InputProps={{
                            readOnly: true,
                          }}
                          inputProps={{ "data-testid": "txt-field" }}
                          onChange={handleEmailChange}
                        />
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ) : (
            <Box className="custom-second-loader">
              <Loader />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MailReview;
