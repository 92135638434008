import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function MailSentPage() {
  const navigate = useNavigate();

  const handleRunCampaign = () => {
    navigate("MailSentPage");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <CheckCircleOutlineIcon
        sx={{ fontSize: 80, color: "green", marginBottom: 2 }}
      />
      <Typography variant="h4" fontWeight={700}>
        Mail Sent Successfully!
      </Typography>
      <Typography variant="subtitle1" sx={{ marginTop: 2, marginBottom: 4 }}>
        Your email has been sent to the recipients. You can now run your
        campaign.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleRunCampaign}
      >
        Run Campaign
      </Button>
    </Box>
  );
}
