import { postService } from "../../config/auth";
// import { useNavigate } from "react-router-dom";
import { SET_SELECTED_VID, SET_SELECTED_ROWS, SET_SELECTED_DATA } from "./actionTypes";
import AuthUser from "../../config/AuthUser";

export const setSelectedPersons = (persons: any) => ({
  type: "SET_SELECTED_PERSONS",
  payload: persons,
});

export const setSelectedPersonData = (personData: any) => ({
  type: "SET_SELECTED_PERSON_DATA",
  payload: personData,
});

export const lists = async () => {
  try {
    const response: any = await postService(`/hubspot_lists`, {});
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const updateCampaign = async () => {
  try {
    const response: any = await postService(
      `/update_sf_campaign_names_on_azure`,
      {}
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const retrieveCampaign = async () => {
  try {
    const response: any = await postService(
      `/retrieve_sf_campaign_names_from_azure`,
      {}
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const retrieveNative = async () => {
  try {
    const response: any = await Promise.resolve(postService(
      `/camp_contact_data`,
      {"action": "campaign_list", "campaign_name": ""}
    ));
    return response;
  } catch (e: any) {
    console.error(e);
  }
};
export const retrieveNativecontactDetails = async (campaign_name : any ,value?:any ,pageIndex?: any,pageSize?: any ) => {
  try {
    const response: any = await postService(
      `/camp_contact_data`,
      {"action": "contact_list", "campaign_name": campaign_name , searchValue: value ,  cursor: pageIndex,
        offset: pageSize}
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const allContacts = async (id: any) => {
  try {
    const response: any = await postService(`/all_contact`, { list_id: id });
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const salesforceContacts = async (id: any) => {
  try {
    const response: any = await postService(`/fetch_contacts_from_sf`, {
      campaign_name: id,
    });
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const contactDetails = async (ids: any, dispatch: any) => {
  try {
    const response: any = await postService(`/contact_details`, {
      contact_vid: ids,
      fetch_owner_info: "1",
    });
    dispatch({ type: SET_SELECTED_VID, payload: ids });
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const getProfile = async (id: any) => {
  try {
    const response: any = await postService(`/get_profile`, id);
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const generateMail = async (payload: any) => {
  try {
    const response: any = await postService(`/generate_mail`, payload);
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const sendEmail = async (payload: any) => {
  try {
    const response: any = await postService(`/send_emails`, payload);
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const login = async (payload: any) => {
  try {
    const response: any = await postService(`/login`, payload);
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const register = async (payload: any) => {
  try {
    const response: any = await postService(`/register`, payload);
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const handleHubspotListData = (hubspotList: any) => ({
  type: "SET_HUBSPOT_LIST_DATA",
  payload: hubspotList,
});

export const fetchContactsAutomatedFollowUp = async (payload: any) => {
  try {
    const response: any = await postService(
      `/fetch_automation_alertswitch_for_contacts`,
      payload
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const updateSelectedContactsAutomatedFollowUp = async (payload: any) => {
  try {
    const response: any = await postService(
      `/update_alertswitch_status_of_contacts`,
      { alert_switch_data: payload }
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const switchAllContactsAutomatedFollowUp = async (payload: any) => {
  try {
    const response: any = await postService(
      `/switch_for_all_contacts_alert_for_follow_up`,
      payload
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const fetchUnsubscribedUsersFollowUp = async (payload: any) => {
  try {
    const response: any = await postService(
      `/fetch_unsubscribed_display_contacts`,
      payload
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const updateUnsubscribedUsersFollowUp = async (payload: any) => {
  try {
    const response: any = await postService(`/update_unsubscribed_users`, {
      data: payload,
    });
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const mailReviewContacts = async (payload: any) => {
  try {
    const response: any = await postService(
      `/review_mail_fetch_display_contact_details`,
      payload
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const fetchMailOfContact = async (payload: any) => {
  try {
    const response: any = await postService(
      `/fetch_mails_of_a_contact`,
      payload
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};
export const searchAutomationAdminContacts = async (payload: any) => {
  try {
    const response: any = await postService(
      `/search_automation_admin_contacts`,
      {
        firstName: payload,
      }
    );
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const searchMailReviewContacts = async (payload: any) => {
  try {
    const response: any = await postService(`/search_review_mail_contacts`, {
      firstName: payload,
    });
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const searchUnsubscribeContacts = async (payload: any) => {
  try {
    const response: any = await postService(`/search_unsubscribe_contacts`, {
      firstName: payload,
    });
    return response;
  } catch (e: any) {
    console.error(e);
  }
};

export const setSelectedRows = (rows: string[]) => ({
  type: SET_SELECTED_ROWS,
  payload: rows
});

export const setSelectedData = (data: any[]) => ({
  type: SET_SELECTED_DATA,
  payload: data
});
