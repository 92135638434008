import {
  SET_LOADING,
  GET_EMAIL_DETAIL_FAILURE,
  GET_EMAIL_DETAIL_START,
  GET_EMAIL_DETAIL_SUCCESS,
  SET_HEADER_STEP_ONE,
  SET_HEADER_STEP_THREE,
  SET_HEADER_STEP_TWO,
  UPDATE_SELECTED_RADIO_VALUE,
  UPDATE_SALESFORCE_DATA,
  UPDATE_NATIVE_DATA,
  SET_GET_PROFILE_DATA,
  SET_GET_NAME_DATA,
} from "./actionTypes";

// Action Creators
export const getEmailDetailStart = () => ({
  type: GET_EMAIL_DETAIL_START,
});

export const getEmailDetailSuccess = (response: any) => ({
  type: GET_EMAIL_DETAIL_SUCCESS,
  payload: response,
});

export const getEmailDetailFailure = (error: any) => ({
  type: GET_EMAIL_DETAIL_FAILURE,
  payload: error,
});

export const setHeaderStepOneCompleted = () => ({
  type: SET_HEADER_STEP_ONE,
});

export const setHeaderStepTwoCompleted = () => ({
  type: SET_HEADER_STEP_TWO,
});

export const setHeaderStepThreeCompleted = () => ({
  type: SET_HEADER_STEP_THREE,
});

export const setLoader = (action: any) => ({
  type: SET_LOADING,
  payload: action,
});

export const updateSelectedRadioValue = (value: any) => ({
  type: UPDATE_SELECTED_RADIO_VALUE,
  payload: value,
});

export const updateSalesforceData = (value: any) => ({
  type: UPDATE_SALESFORCE_DATA,
  payload: value,
});

export const updateNativeData = (value: any) => ({
  type: UPDATE_NATIVE_DATA,
  payload: value,
});

export const updateGetProfileData = (value: any) => ({
  type: SET_GET_PROFILE_DATA,
  payload: value,
});
export const updateNameValue = (value: any) => ({
  type: SET_GET_NAME_DATA,
  payload: value,
});

export const updateSelectedRows = (value: any) => ({
  type: UPDATE_NATIVE_DATA,
  payload: value,
});

export const updateSelectedSalesforceData = (value: any) => ({
  type: SET_GET_PROFILE_DATA,
  payload: value,
});
export const updateSelectedNativeData  = (value: any) => ({
  type: SET_GET_NAME_DATA,
  payload: value,
});