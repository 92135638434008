import { GET_SELECTED_USER } from "../Actions/actionTypes";

const initialState = {
  selectedList: "",
};

const selctedValueReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SELECTED_USER:
      return { ...state, selectedList: action.payload };
    default:
      return state;
  }
};

export default selctedValueReducer;
