import { SET_GET_NAME_DATA } from "../Actions/actionTypes";

const initialState = {
  nameValue: "",
};

const nameValueReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_GET_NAME_DATA:
      return { ...state, nameValue: action.payload };
    default:
      return state;
  }
};

export default nameValueReducer;
