import { Box, IconButton, Typography, Button, Avatar, Card, CardHeader, Tooltip } from "@mui/material";
import { useEffect, useRef } from "react";
import OneIcon from "../../Assets/Icons/oneCircle.svg";
import OneIconChecked from "../../Assets/Icons/oneCircleChecked.svg";
import OneText from "../../Assets/Icons/Contacts.svg";
import TwoIcon from "../../Assets/Icons/twoIcon.svg";
import TwoIconWhite from "../../Assets/Icons/twoIconWhite.svg";
import TwoText from "../../Assets/Icons/twoText.svg";
import ThreeIcon from "../../Assets/Icons/ThreeIcon.svg";
import ThreeIconWhite from "../../Assets/Icons/threeIconWhite.svg";
import ThreeText from "../../Assets/Icons/ThreeText.svg";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MarketMinderLogo from "../../Assets/Icons/MarketLogo.svg";
import compunnelLogo from "../../Assets/Icons/compunnel-logo.svg";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import crossIcon from "../../Assets/Icons/crossIcon.svg";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import MarketMinderTxt from "../../Assets/Icons/Market_Minder_txt.svg";
import AutomatedIcon from "../../Assets/Icons/AutomatedFollupIcon.svg";
import MailReviewIcon from "../../Assets/Icons/MailReviewIcon.svg";
import UnsubscribeIcon from "../../Assets/Icons/unsubscribeIcon.svg";
import LogoutIcon from "../../Assets/Icons/LogoutIcon.svg";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import { red } from "@mui/material/colors";

const drawerWidth = 250;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Header() {
  const navigate = useNavigate();
  let location = useLocation();

  const headerStore = useSelector((state: any) => state.radioButtonValueReducer);

  const headerStore1 = useSelector((state: any) => state.nameValueReducer);
console.log("headerStore1", headerStore1);
  /* istanbul ignore next */
  const handleHubspotClick = () => {
    if (headerStore.stepOneCompleted) {
      navigate("/hubspot");
    }
    navigate("/hubspot");
  };

  const handleLinkedinClick = () => {
    if (headerStore.stepTwoCompleted) {
      navigate("/linkedin-search");
    }
  };

  /* istanbul ignore next */
  const handleGenerateMailClick = () => {
    if (headerStore.stepTwoCompleted) {
      navigate("/generate-mail");
    }
  };

  const determineTwoIcon = () => {
    if (location.pathname === "/linkedin-search") {
      return TwoIconWhite;
    } else {
      return headerStore.stepTwoCompleted &&
        location.pathname !== "/linkedin-search"
        ? OneIconChecked
        : TwoIcon;
    }
  };


  const renderAppBarContent = () => (
    <Box className="app-bar-content1" style={{height: "70px"}}>
      <Button
        onClick={handleHubspotClick}
        data-testid="btn-1"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <img
          className="custom-img-icon"
          src={
            headerStore.stepOneCompleted && location.pathname !== "/hubspot"
              ? OneIconChecked
              : OneIcon
          }
          alt="One-icon"
        />
        <img src={OneText} alt="One-text" />
      </Button>

      <Divider className="custom-divider" />
      <Button
        data-testid="btn-2"
        sx={{ display: "flex", flexDirection: "column" }}
        onClick={handleLinkedinClick}
      >
        <img
          className="custom-img-icon"
          src={determineTwoIcon()}
          alt="Two-icon"
        />
        <img src={TwoText} alt="Two-text" />
      </Button>
      <Divider className="custom-divider" />

      <Button
        data-testid="btn-3"
        sx={{ display: "flex", flexDirection: "column" }}
        onClick={handleGenerateMailClick}
      >
        <img
          className="custom-img-icon"
          src={
            location.pathname === "/generate-mail" ? ThreeIconWhite : ThreeIcon
          }
          alt="Three-icon"
        />
        <img src={ThreeText} alt="Three-text" />
      </Button>
    </Box>
  );


  const renderTopHeader = () => (
    <Box
      className="app-header-top"
      display={"flex"}
      alignContent={"space-between"}
      sx={{ height: "80px" }}
    >
      <Box display={"flex"} alignItems={"center"} sx={{width: "100%"}}>
        <Box className="logoRightHeader">
          <Box
            className="logoRight"
            onClick={handleHubspotClick}
            data-testid="mmHeader"
          >
            <img src={MarketMinderLogo} alt="" />
            <img style={{ marginBottom: "8px" }} src={MarketMinderTxt} />
          </Box>

          <Box>
            <Card className="nameCard" style={{ marginBottom: "8px" }}>
            <CardHeader className="nameCardHeader"
            avatar={
              <Tooltip title={fullName || "No Name"} arrow>
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                {initials || "?"} 
              </Avatar>
              </Tooltip>
            } >

            </CardHeader>
            </Card>
          
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderCustomAppBar = () => (
    <Box className="app-bar-box">
      <AppBar position="static" className="app-bar">
        {renderAppBarContent()}
      </AppBar>
    </Box>
  );

  const renderContentBasedOnPath = () =>
    window.location.pathname.includes("/generate-mail") ||
    window.location.pathname.includes("/hubspot") ||
    window.location.pathname.includes("/linkedin-search")
      ? renderCustomAppBar()
      : null;

  //get user name from localstorage
  const userDetailsString = localStorage.getItem("UserDetails");
  let firstName = "";
  let lastName = "";
  let fullName = "";

  if (userDetailsString) {
    const userDetails = JSON.parse(userDetailsString); // Convert JSON string to object
    firstName = userDetails.firstName || "";
    lastName = userDetails.lastName || "";
    fullName = `${firstName} ${lastName}`.trim();  
  } 

  // Get the initials for the Avatar
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();

  /* istanbul ignore next */
  return (
    <Box className="abc">
      {renderTopHeader()}
      {renderContentBasedOnPath()}
    </Box>
  );
}
